(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/system-selector/system-selector-menu.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/system-selector/system-selector-menu.js');
"use strict";

const {
  ItemsMenu
} = svs.ui.ReactItemsMenu;
const {
  ReactIcon
} = svs.ui;
const {
  SystemSelectorMenuItem
} = svs.components.tipsen.routePlaySpela.systemSelector;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  Form,
  ControlGroup,
  Select,
  Option
} = svs.ui.reactForm;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  systemConfig,
  NONE_SELECTED_SYSTEM
} = svs.components.tipsen.routePlaySpela.systemSelector.constants;
const SystemSelectorMenu = _ref => {
  let {
    selectedItem,
    handleClick,
    systems
  } = _ref;
  const isExtraSmall = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const itemsMenuClassNames = ['items-menu-tipsen-system-selector-brand'];
  const branding = useBranding();
  if (branding.length > 1) {
    itemsMenuClassNames.push(branding[branding.length - 1]);
  }
  if (!isExtraSmall) {
    return React.createElement("div", {
      className: "tipsen-system-selector-menu"
    }, React.createElement("div", {
      className: "tipsen-system-selector-menu-choose f-550"
    }, React.createElement("div", {
      className: "tipsen-system-selector-inner-div"
    }, React.createElement("div", {
      className: "margin-right"
    }, "V\xE4lj \xF6nskat system"), React.createElement(ReactIcon, {
      color: "fc-red",
      icon: "arrow-right"
    }))), React.createElement(ItemsMenu, {
      branding: "game-menu",
      centered: true,
      className: itemsMenuClassNames.join(' ')
    }, systems.map(sys => React.createElement(SystemSelectorMenuItem, {
      datatestid: "system-selector-".concat(sys.toLowerCase()),
      handleClick: handleClick,
      key: sys,
      selectedItem: selectedItem,
      system: sys
    }))));
  }
  return React.createElement(Form, {
    isVertical: true
  }, React.createElement(ControlGroup, {
    v2: true
  }, React.createElement(Select, {
    "data-testid": "system-type-selector",
    onChange: event => handleClick(event.target.value),
    v2: true,
    value: selectedItem
  }, React.createElement(Option, {
    disabled: true,
    value: NONE_SELECTED_SYSTEM
  }, "V\xE4lj \xF6nskat system"), systems.map(sys => React.createElement(Option, {
    key: sys,
    value: sys
  }, systemConfig[sys].label)))));
};
SystemSelectorMenu.protoTypes = {
  selectedItem: PropTypes.string,
  handleClick: PropTypes.func
};
setGlobal('svs.components.tipsen.routePlaySpela.systemSelector.SystemSelectorMenu', SystemSelectorMenu);

 })(window);