(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/system-selector/system-selector.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/system-selector/system-selector.js');
"use strict";

const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  useState,
  useEffect
} = React;
const {
  SYSTEM_MSYS,
  SYSTEM_RSYS,
  SYSTEM_USYS,
  SYSTEM_REDUCERA_FRITT
} = svs.components.tipsen.engine.constants.systems;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  selectSystem
} = svs.components.tipsen.engine.actions;
const {
  selectRSystem,
  selectUSystem,
  selectLastSelectedSystem
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  selectRSystems,
  selectUSystems,
  selectSystemTypes
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  SystemSelectorComponent,
  SystemSelectorMenu,
  SystemReduceFree
} = svs.components.tipsen.routePlaySpela.systemSelector;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  SystemSelectorWrapper
} = svs.components.tipsen.routePlaySpela.systemSelector;
const SystemSelector = () => {
  const couponId = useCouponId();
  const rSystems = useSelector(selectRSystems);
  const uSystems = useSelector(selectUSystems);
  const systemTypes = useSelector(selectSystemTypes);
  const filteredSystemTypes = systemTypes.filter(sys => sys !== SYSTEM_MSYS);
  const rSystem = useSelector(state => selectRSystem(state, couponId));
  const uSystem = useSelector(state => selectUSystem(state, couponId));
  const isExtraSmall = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const lastSelectedSys = useSelector(state => selectLastSelectedSystem(state, couponId));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectSystem({
      systemTypes,
      couponId,
      system: lastSelectedSys,
      rSystem: lastSelectedSys === systemTypes.indexOf(SYSTEM_RSYS) ? rSystem : null,
      uSystem: lastSelectedSys === systemTypes.indexOf(SYSTEM_USYS) ? uSystem : null
    }));
  }, []);
  const onClickSys = (systemType, systemNum) => {
    if (systemNum === rSystem || systemNum === uSystem || !systemNum) {
      systemNum = null;
    }
    dispatch(selectSystem({
      systemTypes,
      couponId,
      system: systemTypes.indexOf(systemType),
      rSystem: systemType === SYSTEM_RSYS ? systemNum : null,
      uSystem: systemType === SYSTEM_USYS ? systemNum : null
    }));
  };
  const selectedSystem = lastSelectedSys === systemTypes.indexOf(SYSTEM_MSYS) ? SYSTEM_RSYS : systemTypes[lastSelectedSys];
  const [selectedItem, setSelectedItem] = useState(selectedSystem);
  useEffect(() => {
    setSelectedItem(systemTypes[lastSelectedSys]);
  }, [lastSelectedSys, systemTypes]);
  const handleClick = target => {
    setSelectedItem(target);
    dispatch(selectSystem({
      systemTypes,
      couponId,
      system: systemTypes.indexOf(target)
    }));
  };
  return React.createElement("div", {
    className: "tipsen-system-selector"
  }, React.createElement(SystemSelectorWrapper, {
    elementClassName: isExtraSmall ? 'tipsen-systems-selector-menu-div' : 'tipsen-systems-selector-menu-paper',
    isExtraSmall: isExtraSmall
  }, isExtraSmall && React.createElement("div", {
    className: "tipsen-system-selector-heading f-center f-800 f-medium"
  }, "V\xE4lj System"), React.createElement(SystemSelectorMenu, {
    handleClick: handleClick,
    selectedItem: selectedItem,
    systems: filteredSystemTypes
  }), selectedItem === SYSTEM_RSYS && React.createElement(SystemSelectorComponent, {
    onClick: onClickSys,
    system: rSystem !== null && rSystem !== void 0 ? rSystem : rSystems[rSystem],
    systems: rSystems,
    systemType: SYSTEM_RSYS
  }), selectedItem === SYSTEM_USYS && React.createElement(SystemSelectorComponent, {
    onClick: onClickSys,
    system: uSystem !== null && uSystem !== void 0 ? uSystem : uSystems[uSystem],
    systems: uSystems,
    systemType: SYSTEM_USYS
  }), selectedItem === SYSTEM_REDUCERA_FRITT && React.createElement(SystemReduceFree, {
    isExtraSmall: isExtraSmall
  })));
};
setGlobal('svs.components.tipsen.routePlaySpela.systemSelector.SystemSelector', SystemSelector);

 })(window);