(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/xpert-selector/xpert-button.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/xpert-selector/xpert-button.js');
"use strict";

const {
  ReactButton
} = svs.ui;
const {
  Loader
} = svs.components.ReactLoader;
const {
  Choose,
  When,
  Otherwise
} = svs.components.lbUtils.reactConditionals;
const XpertButton = _ref => {
  let {
    isDisabled,
    onClick,
    isLoading,
    children
  } = _ref;
  return React.createElement(ReactButton, {
    className: "xpert-apply-btn",
    disabled: isDisabled || isLoading,
    inverted: true,
    onClick: onClick
  }, React.createElement(Choose, null, React.createElement(When, {
    condition: isLoading
  }, React.createElement(Loader, {
    size: 200
  })), React.createElement(Otherwise, null, children)));
};
setGlobal('svs.components.tipsen.routePlaySpela.XpertButton', XpertButton);

 })(window);