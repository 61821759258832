(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/xpert-selector/xpert-selector-slider.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/xpert-selector/xpert-selector-slider.js');
"use strict";

const {
  SlideSelector
} = svs.components.tipsen;
const XpertSelectorSlider = _ref => {
  let {
    xpertSystems,
    setSize,
    size,
    isSizeDisabled
  } = _ref;
  const xpertNumberClasses = ['xpert-input'];
  if (isSizeDisabled(size)) {
    xpertNumberClasses.push('xpert-input-size-disabled');
  }
  return React.createElement("div", null, React.createElement("div", {
    className: "f-550 tipsen-pix-selector-header-txt"
  }, "V\xE4lj belopp"), React.createElement(SlideSelector, {
    className: "xpert-inputs",
    isValueDisabled: isSizeDisabled,
    options: xpertSystems,
    setValue: setSize,
    suffix: " kr",
    value: size
  }));
};
setGlobal('svs.components.tipsen.routePlaySpela.XpertSelectorSlider', XpertSelectorSlider);

 })(window);