(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/system-selector/system-selector-wrapper.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/system-selector/system-selector-wrapper.js');
"use strict";

const Paper = svs.ui.ReactPaper;
const {
  SYSTEM_REDUCERA_FRITT
} = svs.components.tipsen.engine.constants.systems;
const SystemSelectorWrapper = _ref => {
  let {
    isExtraSmall,
    children,
    elementClassName = null,
    systemType
  } = _ref;
  if (isExtraSmall && systemType === SYSTEM_REDUCERA_FRITT) {
    return React.createElement(Paper, {
      branding: "sport",
      className: elementClassName ? elementClassName : null
    }, children);
  } else if (!isExtraSmall && systemType !== SYSTEM_REDUCERA_FRITT) {
    return React.createElement(Paper, {
      branding: "sport",
      className: elementClassName ? elementClassName : null
    }, children);
  }
  return React.createElement("div", {
    className: elementClassName ? elementClassName : null
  }, children);
};
setGlobal('svs.components.tipsen.routePlaySpela.systemSelector.SystemSelectorWrapper', SystemSelectorWrapper);

 })(window);