(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/system-selector/system-selector-menu-item.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/system-selector/system-selector-menu-item.js');
"use strict";

const {
  LinkItem,
  ItemsMenuIcon
} = svs.ui.ReactItemsMenu;
const {
  systemConfig
} = svs.components.tipsen.routePlaySpela.systemSelector.constants;
const SystemSelectorMenuItem = _ref => {
  let {
    handleClick,
    system,
    selectedItem,
    datatestid
  } = _ref;
  return React.createElement(LinkItem, {
    className: "system-selector-menu-item",
    Component: "button",
    "data-testid": datatestid,
    label: systemConfig[system].label,
    onClick: () => handleClick(system),
    selected: selectedItem === system
  }, React.createElement(ItemsMenuIcon, {
    icon: systemConfig[system].icon,
    position: "left",
    size: "200"
  }));
};
setGlobal('svs.components.tipsen.routePlaySpela.systemSelector.SystemSelectorMenuItem', SystemSelectorMenuItem);

 })(window);