(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/system-selector/system-reduce-free.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/system-selector/system-reduce-free.js');
"use strict";

const {
  useState,
  useCallback,
  useEffect,
  useRef
} = React;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  Form,
  ControlGroup,
  Inputv2
} = svs.ui.reactForm;
const {
  setReduceModeCount
} = svs.components.tipsen.engine.actions;
const {
  selectReduceCount
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  SYSTEM_REDUCERA_FRITT
} = svs.components.tipsen.engine.constants.systems;
const {
  TipsenLink
} = svs.components.tipsen.tipsen_link;
const {
  SystemSelectorWrapper
} = svs.components.tipsen.routePlaySpela.systemSelector;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;


function restArguments(func, startIndex) {
  startIndex = startIndex === undefined || startIndex === null ? func.length - 1 : +Number(startIndex);
  return function () {
    const length = Math.max(arguments.length - startIndex, 0);
    const rest = Array(length);
    let index = 0;
    for (; index < length; index++) {
      rest[index] = arguments[index + startIndex];
    }
    switch (startIndex) {
      case 0:
        return func.call(this, rest);
      case 1:
        return func.call(this, arguments[0], rest);
      case 2:
        return func.call(this, arguments[0], arguments[1], rest);
    }
    const args = Array(startIndex + 1);
    for (index = 0; index < startIndex; index++) {
      args[index] = arguments[index];
    }
    args[startIndex] = rest;
    return func.apply(this, args);
  };
}
function debounce(func, wait, immediate) {
  let timeout;
  let previous;
  let args;
  let result;
  let context;
  const later = function () {
    const passed = Date.now() - previous;
    if (wait > passed) {
      timeout = setTimeout(later, wait - passed);
    } else {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
      }
      if (!timeout) {
        args = context = null;
      }
    }
  };
  const debounced = restArguments(function (_args) {
    context = this;
    args = _args;
    previous = Date.now();
    if (!timeout) {
      timeout = setTimeout(later, wait);
      if (immediate) {
        result = func.apply(context, args);
      }
    }
    return result;
  });
  debounced.cancel = function () {
    clearTimeout(timeout);
    timeout = args = context = null;
  };
  return debounced;
}
const SystemReduceFree = _ref => {
  let {
    isExtraSmall
  } = _ref;
  const couponId = useCouponId();
  const dispatch = useDispatch();
  const reduceCount = useSelector(state => selectReduceCount(state, couponId));
  const [rowNumbers, setRowNumbers] = useState(reduceCount);
  const [rowErrors, setRowErrors] = useState({
    error: false,
    message: ''
  });
  const setError = useCallback(message => {
    setRowErrors({
      error: true,
      message
    });
  }, []);
  const removeError = () => {
    setRowErrors({
      error: false,
      message: ''
    });
  };
  const debouncer = useRef();
  useEffect(() => {
    debouncer.current = debounce(value => {
      if (value < 20) {
        setError('Det måste vara minst 20 rader');
      } else if (value > 2200) {
        setError('Det får inte vara mer än 2200 rader');
      }
    }, 500);
    return () => {
      debouncer.current.cancel();
    };
  }, [setError]);
  useEffect(() => {
    const value = Number(rowNumbers);
    const rangeIsOk = value >= 20 && value <= 2200;
    if (!Number.isNaN(value) && rowNumbers && rowNumbers.length > 0) {
      debouncer.current(value);
    }
    if (!rowNumbers || rowNumbers.length === 0 || rangeIsOk) {
      removeError();
      debouncer.current.cancel();
    }
    if (rangeIsOk || !value) {
      dispatch(setReduceModeCount({
        couponId,
        value
      }));
    }
  }, [couponId, dispatch, rowNumbers]);
  const rowInputChange = e => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setRowNumbers(value ? value : '');
  };
  return React.createElement(SystemSelectorWrapper, {
    elementClassName: isExtraSmall ? 'tipsen-reduce-paper' : null,
    isExtraSmall: isExtraSmall,
    systemType: SYSTEM_REDUCERA_FRITT
  }, React.createElement("div", {
    className: "tipsen-system-selector-reduce-input"
  }, React.createElement("div", null, "Med reducera fritt styr du radf\xF6rdelning och pris i ditt system.", ' ', React.createElement(TipsenLink, {
    to: "/".concat(TipsenModuleGameUrl.SYSTEM, "/spelguide")
  }, "L\xE4s mer.")), React.createElement(Form, {
    isVertical: true,
    onSubmit: e => e.preventDefault()
  }, React.createElement(ControlGroup, {
    errorMessage: rowErrors.message,
    hasError: rowErrors.error,
    isLessSpace: true,
    v2: true
  }, React.createElement(Inputv2, {
    id: "reactreduceRows",
    inputHelpText: "Ange antal rader mellan 20 - 2200",
    label: "Ange antal rader mellan 20 - 2200",
    min: "0",
    onChange: rowInputChange,
    suffix: rowNumbers ? 'rader' : '',
    type: "tel",
    value: rowNumbers !== null && rowNumbers !== void 0 ? rowNumbers : ''
  })))));
};
setGlobal('svs.components.tipsen.routePlaySpela.systemSelector.SystemReduceFree', SystemReduceFree);

 })(window);