(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/coupon-choice/coupon-toolbox/coupon-toolbox.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/coupon-choice/coupon-toolbox/coupon-toolbox.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  useContext,
  useMemo,
  useEffect,
  useState
} = React;
const {
  CouponTipsinfoChoice
} = svs.components.tipsen.routePlaySpela.couponChoice;
const {
  useDrawSearchParams
} = svs.components.tipsen.drawProvider.hooks;
const {
  DrawContext
} = svs.components.tipsen.drawProvider;
const {
  useRouteMatch
} = ReactRouterDOM;
const {
  useSelector
} = ReactRedux;
const {
  selectSystemType
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  EngineProviderContext
} = svs.components.tipsen.engine;
const {
  SYSTEM_USYS,
  SYSTEM_REDUCERA_FRITT
} = svs.components.tipsen.engine.constants.systems;
const {
  analytics
} = svs.components;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  DrawState
} = svs.components.sport.tipsenShared;
const {
  GameGuideButton,
  OddsButton,
  SettingsButton,
  ClearButton,
  PixButton
} = svs.components.tipsen.couponChoiceButton;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  SettingsSelector,
  XpertSelector
} = svs.components.tipsen.routePlaySpela;
const {
  ExpandableArea
} = svs.components.tipsen.expandableArea;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const useArrowPosition = elementSelector => {
  const [couponToolboxGrid, setCouponToolboxGrid] = useState();
  useEffect(() => {
    if (couponToolboxGrid) {
      return;
    }
    setCouponToolboxGrid(document.querySelector('.coupon-toolbox'));
  }, [elementSelector, couponToolboxGrid]);
  return useMemo(() => {
    if (couponToolboxGrid && elementSelector) {
      const couponToolboxGridLeftPosition = couponToolboxGrid.getBoundingClientRect().left;
      const element = couponToolboxGrid.querySelector(elementSelector);
      if (element) {
        const elementRect = element.getBoundingClientRect();
        return elementRect.left + elementRect.width / 2 - couponToolboxGridLeftPosition;
      }
      return 0;
    }
  }, [elementSelector, couponToolboxGrid]);
};
const CouponToolbox = _ref => {
  let {
    isCouponEmpty,
    isShowingPix,
    isShowingSettings,
    outcomeInfoChoises,
    onActivateNone,
    onActivatePix,
    onActivateSettings,
    onClearCoupon,
    tipsinfoOptions,
    singleCouponChoice
  } = _ref;
  const {
    productId,
    drawNumber
  } = useContext(DrawContext);
  const toParams = useDrawSearchParams(productId, drawNumber);
  const routesMatch = useRouteMatch();
  const {
    currentEngine
  } = useContext(EngineProviderContext);
  const draw = useCurrentDraw();
  const isDrawDefinedNotOpen = draw.drawStateId === DrawState.Defined;
  const handleClickActiveTipsinfo = () => {
    onActivateSettings();
  };
  const couponId = useCouponId();
  const systemType = useSelector(state => selectSystemType(state, couponId));
  const isUsystem = systemType === SYSTEM_USYS;
  const isReduceSystem = systemType === SYSTEM_REDUCERA_FRITT;
  const eventTypeId = currentEngine.outcomes.eventTypeId;
  const isScoreProduct = eventTypeId === EventTypeId.RESULT;
  const hidePixChoice = isUsystem || isReduceSystem || isScoreProduct;
  let elementSelector;
  if (isShowingPix) {
    elementSelector = '.coupon-toolbox-pix-button';
  } else if (isShowingSettings) {
    elementSelector = '.coupon-toolbox-settings-button';
  }
  const arrowPosition = useArrowPosition(elementSelector);
  const isMedium = useMediaQuery(breakpoints.down(BREAKPOINT_MD));
  const isExtraSmall = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  let contextMenuEntries = [];
  if (!hidePixChoice) {
    contextMenuEntries.push({
      icon: 'pix',
      isDisabled: isDrawDefinedNotOpen,
      onClick: onActivatePix,
      title: 'Fyll i med PIX!'
    });
  }
  contextMenuEntries.push({
    icon: 'settings',
    onClick: handleClickActiveTipsinfo,
    title: 'Anpassa'
  }, {
    'data-testid': 'clear-button',
    icon: 'dustbin',
    isDisabled: isCouponEmpty,
    onClick: onClearCoupon,
    title: 'Rensa'
  });
  contextMenuEntries = contextMenuEntries.map(menuEntry => _objectSpread(_objectSpread({}, menuEntry), {}, {
    onClick: () => {
      menuEntry.onClick();
      analytics.trackEvent({
        category: "Nya tipsen",
        action: menuEntry.title,
        opt_label: location.pathname
      });
    }
  }));
  const getGridClassName = () => {
    if (singleCouponChoice) {
      return 'single-coupon-toolbox-grid';
    }
    if (isScoreProduct) {
      return 'odds-coupon-toolbox-grid';
    }
    if (hidePixChoice) {
      return 'no-pix-coupon-toolbox-grid';
    }
    return 'coupon-toolbox-grid';
  };
  return React.createElement(React.Fragment, null, isMedium && React.createElement("h2", {
    className: "tipsen-header f-center f-medium"
  }, "Kupong"), React.createElement("div", {
    className: "coupon-toolbox ".concat(getGridClassName()),
    "data-testid": "coupon-toolbox"
  }, !singleCouponChoice && React.createElement(React.Fragment, null, isMedium && React.createElement(React.Fragment, null, React.createElement(GameGuideButton, {
    routesMatch: routesMatch,
    toParams: toParams
  }), isScoreProduct && React.createElement(OddsButton, {
    routesMatch: routesMatch,
    toParams: toParams
  })), React.createElement(SettingsButton, {
    isShowingSettings: isShowingSettings,
    onClick: onActivateSettings
  })), React.createElement(ClearButton, {
    isCouponEmpty: isCouponEmpty,
    onClearCoupon: onClearCoupon
  }), !hidePixChoice && React.createElement(PixButton, {
    isShowingPix: isShowingPix,
    onClick: onActivatePix
  })), isShowingSettings && isExtraSmall && React.createElement(CouponTipsinfoChoice, {
    isOpen: isShowingSettings,
    onActivateNone: onActivateNone,
    outcomeInfoChoises: outcomeInfoChoises,
    tipsinfoOptions: tipsinfoOptions
  }), !singleCouponChoice && React.createElement(ExpandableArea, {
    arrowPosition: arrowPosition,
    "data-testid": "expandable-area",
    isCouponMenu: true,
    isOpen: isShowingPix && !hidePixChoice || isShowingSettings
  }, isShowingPix && !hidePixChoice && React.createElement(XpertSelector, {
    isOpen: isShowingPix,
    onCloseXpert: onActivateNone
  }), isShowingSettings && React.createElement(SettingsSelector, {
    onClose: onActivateNone,
    tipsinfoOptions: tipsinfoOptions
  })));
};
setGlobal('svs.components.tipsen.routePlaySpela.couponChoice.CouponToolbox', CouponToolbox);

 })(window);