(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/xpert-selector/xpert-selector.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/xpert-selector/xpert-selector.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  useState,
  useEffect,
  useContext,
  useCallback,
  useReducer,
  useMemo
} = React;
const {
  ReactIconButton,
  ReactIcon
} = svs.ui;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  useAnalyses
} = svs.components.sportinfo.gameAnalysesRedux.hooks;
const {
  SYSTEMS,
  getSystemKeyFromNumber
} = svs.components.sport.systems;
const {
  analytics
} = svs.components;
const {
  DrawState
} = svs.components.sport.tipsenShared;
const {
  Choose,
  When,
  ForEach
} = svs.components.lbUtils.reactConditionals;
const {
  selectXpertMsys,
  selectXpertRsys,
  selectDefaultPixSize
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  selectHasUserSigns,
  selectUserSigns,
  selectHalfHedges,
  selectFullHedges,
  selectUserSystemSize,
  selectXpertAnalysisAuthorId,
  selectRSystem,
  selectPixSize
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  XPERT_WAGER_MSYS,
  XPERT_WAGER_RSYS
} = svs.components.tipsen.engine.constants.xpertWagerSystemTypes;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  DrawContext
} = svs.components.tipsen.drawProvider;
const {
  LoadingKeys
} = svs.components.tipsen.loadingState.constants;
const {
  selectIsKeyLoading
} = svs.components.tipsen.loadingState.selectors;
const {
  selectSelectedSystemType,
  selectCouponEmpty
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  SYSTEM_MSYS,
  SYSTEM_RSYS
} = svs.components.tipsen.engine.constants.systems;
const BASEROW_EMPTY = 'BASEROW_EMPTY';
const BASEROW_USER_SIGNS = 'BASEROW_USER_SIGNS';
const {
  XpertBtnMsys,
  XpertBtnRsys
} = svs.components.tipsen.xpertenBtnSelector;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  useIsCurrentDrawPlayable
} = svs.components.tipsen.hooks;
const {
  getCompetitionFactoryInstance
} = svs.components.tipsen.competition.competitionFactoryInstance;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const {
  useGenerateSportPixCouponAction
} = svs.components.tipsen.engine.utils.helpers;
const {
  XpertSelectorSlider,
  XpertButton
} = svs.components.tipsen.routePlaySpela;
const pixChoice = RTK.createAction('pixChoice');
const clearSystemChoice = RTK.createAction('clearSystemChoice');
const systemChoice = RTK.createAction('systemChoice');
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const getAllowedBaseRowHedgeLimits = (size, outcomeCount, maxCombination) => {
  const hedges = new Array(outcomeCount + 1).fill(0);
  const max = Math.max(Math.min(maxCombination || outcomeCount, outcomeCount), 0);
  for (let i = 2; i <= outcomeCount; i++) {
    let _size = size;
    while (_size % i === 0) {
      if (i <= max) {
        hedges[i] += 1;
      }
      _size /= i;
    }
  }
  for (let i = hedges.length - 1; i > 1; i--) {
    const prevI = i - 1;
    if (i ** (hedges[prevI] + hedges[i]) <= size) {
      hedges[prevI] = hedges[prevI] + hedges[i];
    } else {
      hedges[prevI] = Math.floor(Math.log(size) / Math.log(prevI)) / 1;
    }
  }
  return hedges.slice(2);
};
const XpertSelector = _ref => {
  var _draw$drawEvents, _rSystemPix$pixChoice, _engineDefinition$out;
  let {
    isOpen,
    onCloseXpert
  } = _ref;
  const {
    useCanPlayInCompetitionDraw,
    useActiveCompetition
  } = getCompetitionFactoryInstance().getCompetitionHooksForCms();
  const dispatch = useDispatch();
  const {
    productId,
    drawNumber
  } = useContext(DrawContext);
  const isDrawPlayable = useIsCurrentDrawPlayable();
  const generateSportPixCoupon = useGenerateSportPixCouponAction();
  const couponId = useCouponId();
  const draw = useCurrentDraw();
  const competition = useActiveCompetition();
  const canPlayInCompetitionDraw = useCanPlayInCompetitionDraw({
    productId: draw.productId,
    drawNumber: draw.drawNumber
  });
  const drawEventTypeId = (_draw$drawEvents = draw.drawEvents) === null || _draw$drawEvents === void 0 || (_draw$drawEvents = _draw$drawEvents[0]) === null || _draw$drawEvents === void 0 ? void 0 : _draw$drawEvents.eventTypeId;
  const {
    analyses,
    baseRows
  } = useAnalyses(productId, drawNumber, drawEventTypeId);
  const isExtraSmall = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const defaultPixSize = useSelector(state => selectDefaultPixSize(state));
  const xpertMsys = useSelector(selectXpertMsys);
  const rSystems = useSelector(selectXpertRsys);
  const hasUserSigns = useSelector(state => selectHasUserSigns(state, couponId));
  const xpertAnalysisAuthorId = useSelector(state => selectXpertAnalysisAuthorId(state, couponId));
  const userSigns = useSelector(state => selectUserSigns(state, couponId));
  const halfHedges = useSelector(state => selectHalfHedges(state, couponId, 0));
  const fullHedges = useSelector(state => selectFullHedges(state, couponId, 0));
  const systemUserSize = useSelector(state => selectUserSystemSize(state, couponId, 0));
  const isCouponEmpty = useSelector(state => selectCouponEmpty(state, couponId));
  const systemType = useSelector(state => selectSelectedSystemType(state, couponId));
  const pixSize = useSelector(state => selectPixSize(state, couponId, 0));
  const selectedRsystem = useSelector(state => selectRSystem(state, couponId));
  const isLoadingPix = useSelector(state => selectIsKeyLoading(state, LoadingKeys.PIX));
  const [signsWarnings, setSignsWarnings] = useState(false);
  const [baseRow, setBaseRow] = useState(() => {
    if (hasUserSigns) {
      return BASEROW_USER_SIGNS;
    } else if (xpertAnalysisAuthorId) {
      return xpertAnalysisAuthorId;
    }
    return BASEROW_EMPTY;
  });
  const [isFillButtonDisabled, setIsFillButtonDisabled] = useState(false);
  const [standardSize, setStandardSize] = useState(pixSize > 0 ? pixSize : defaultPixSize);
  const [rSystemPix, dispatchRSystemPix] = useReducer((state, action) => {
    switch (action.type) {
      case pixChoice.type:
        {
          const system = action.payload ? state.systemChoice : undefined;
          return _objectSpread(_objectSpread({}, state), {}, {
            pixChoice: action.payload,
            systemChoice: system
          });
        }
      case clearSystemChoice.type:
        return _objectSpread(_objectSpread({}, state), {}, {
          systemChoice: undefined
        });
      case systemChoice.type:
        return _objectSpread(_objectSpread({}, state), {}, {
          systemChoice: action.payload
        });
      default:
        return state;
    }
  }, {
    pixChoice: undefined,
    systemChoice: undefined
  }, state => {
    if (rSystems.includes(selectedRsystem)) {
      return _objectSpread(_objectSpread({}, state), {}, {
        systemChoice: selectedRsystem
      });
    }
    return state;
  });
  const isRsystem = systemType === SYSTEM_RSYS;
  const isStandard = systemType === SYSTEM_MSYS;
  const rSystemSize = (_rSystemPix$pixChoice = rSystemPix.pixChoice) !== null && _rSystemPix$pixChoice !== void 0 ? _rSystemPix$pixChoice : rSystemPix.systemChoice;
  const isSameSystem = isRsystem && selectedRsystem && selectedRsystem === rSystemSize || isStandard && standardSize === pixSize;
  const isDrawDefinedNotOpen = draw.drawStateId === DrawState.Defined;
  const engineDefinition = getDefinitionByProduct(productId);
  const sportType = draw.sportType;
  let outcomeCount;
  const heatmapMaxValue = (_engineDefinition$out = engineDefinition.outcomes.outcomeHeatmap[sportType]) === null || _engineDefinition$out === void 0 ? void 0 : _engineDefinition$out.maxValue;
  if (heatmapMaxValue) {
    outcomeCount = heatmapMaxValue + 1;
  } else {
    outcomeCount = engineDefinition.outcomes.outcomeCount;
  }
  const maxSignsPix = outcomeCount;
  const userSignsMap = useMemo(() => {
    const _userSignsMap = new Map();
    userSigns.forEach(row => {
      const rowCount = row.filter(sign => sign).length;
      if (_userSignsMap.has(rowCount)) {
        _userSignsMap.set(rowCount, _userSignsMap.get(rowCount) + 1);
      } else {
        _userSignsMap.set(rowCount, 1);
      }
    });
    return _userSignsMap;
  }, [userSigns]);
  const largestUserRow = useMemo(() => userSigns.reduce((acc, row) => {
    const selectedSigns = row.filter(v => v).length;
    return selectedSigns > acc ? selectedSigns : acc;
  }, 0), [userSigns]);
  const isPixButtonDisabled = useMemo(() => {
    if (!isDrawPlayable) {
      return true;
    }
    if (competition) {
      return isFillButtonDisabled || isLoadingPix || !canPlayInCompetitionDraw || isDrawDefinedNotOpen;
    }
    return isFillButtonDisabled || isLoadingPix || isDrawDefinedNotOpen;
  }, [isDrawPlayable, competition, isFillButtonDisabled, isLoadingPix, canPlayInCompetitionDraw, isDrawDefinedNotOpen]);
  const calculateNextPossibleSum = userSignsArray => {
    const reversedUserSigns = userSignsArray.reverse();
    for (let i = 1; i < reversedUserSigns.length; i++) {
      if (reversedUserSigns[i] !== 0) {
        reversedUserSigns[i] -= 1;
        reversedUserSigns[i - 1] += 1;
        break;
      }
    }
    let nextPossibleSum = 1;
    reversedUserSigns.reverse().forEach((value, index) => {
      if (value !== 0) {
        nextPossibleSum *= (index + 2) ** value;
      }
    });
    return nextPossibleSum;
  };
  const isSizeDisabled = useCallback(_size => {
    if (baseRow !== BASEROW_USER_SIGNS) {
      return false;
    }
    if (systemUserSize >= _size) {
      return true;
    }
    if (systemUserSize === _size) {
      return false;
    }
    const hedges = getAllowedBaseRowHedgeLimits(_size, outcomeCount, maxSignsPix);
    let hedgeCheck = true;
    const userSignsArray = new Array(outcomeCount).fill(0);
    hedges.forEach((allowedHedgeSize, i) => {
      const userHedgeSize = userSignsMap.has(i + 2) ? userSignsMap.get(i + 2) : 0;
      const userHedgeSizeAllowed = allowedHedgeSize >= userHedgeSize;
      userSignsArray[i] = userHedgeSize;
      hedgeCheck = hedgeCheck && userHedgeSizeAllowed;
    });
    const nextPossibleSum = calculateNextPossibleSum(userSignsArray);
    hedgeCheck = hedgeCheck && nextPossibleSum <= _size;
    return !hedgeCheck;
  }, [baseRow, userSignsMap, systemUserSize, outcomeCount, maxSignsPix]);
  const isSystemDisabled = useCallback(_ref2 => {
    let {
      currentHalfHedges,
      currentFullHedges,
      system
    } = _ref2;
    let systemKey = system;
    if (typeof systemKey === 'number') {
      systemKey = getSystemKeyFromNumber(system);
    }
    const currentSystem = SYSTEMS[systemKey];
    const numOfFullHedgesAvailable = currentSystem.numFullHedges - currentFullHedges;
    const numOfHalfHedgesAvailable = currentSystem.numHalfHedges - currentHalfHedges;
    const halfHedgesCanTurnIntoFullHedges = currentHalfHedges <= numOfFullHedgesAvailable;
    return baseRow === BASEROW_USER_SIGNS && !(numOfFullHedgesAvailable > 0 && numOfHalfHedgesAvailable > 0 || halfHedgesCanTurnIntoFullHedges);
  }, [baseRow]);
  const setRsystemSize = useCallback(function (v) {
    let override = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (override) {
      dispatchRSystemPix(systemChoice(v));
    } else {
      dispatchRSystemPix(pixChoice(v));
    }
  }, []);
  const hasOnlyOneSystem = useMemo(() => xpertMsys.length === 1, [xpertMsys.length]);
  const generateXpertCouponLabel = useMemo(() => {
    let label;
    if (signsWarnings) {
      label = "Max ".concat(maxSignsPix, " tecken per match med PIX");
    } else if (isSameSystem && !isCouponEmpty) {
      label = 'Generera ny PIX!';
      if (hasOnlyOneSystem) {
        label += " f\xF6r ".concat(xpertMsys[0], " kr");
      }
    } else {
      label = 'Låt PIX! fylla i kupongen';
      if (hasOnlyOneSystem) {
        label += " med ".concat(xpertMsys[0], " kr");
      }
    }
    return label;
  }, [hasOnlyOneSystem, isSameSystem, isCouponEmpty, xpertMsys, signsWarnings, maxSignsPix]);
  useEffect(() => {
    if (!selectedRsystem) {
      dispatchRSystemPix(clearSystemChoice());
    }
  }, [selectedRsystem]);
  useEffect(() => {
    if (selectedRsystem && rSystems.includes(selectedRsystem)) {
      setRsystemSize(selectedRsystem, true);
    }
  }, [rSystems, selectedRsystem, setRsystemSize]);
  useEffect(() => {
    if (isRsystem) {
      if (!rSystemSize) {
        setIsFillButtonDisabled(true);
      } else {
        setIsFillButtonDisabled(isSystemDisabled({
          currentHalfHedges: halfHedges,
          currentFullHedges: fullHedges,
          system: rSystemSize
        }));
      }
    }
  }, [rSystemSize, fullHedges, halfHedges, isRsystem, isSystemDisabled]);
  useEffect(() => {
    if (isStandard && standardSize > 0) {
      setIsFillButtonDisabled(isSizeDisabled(standardSize));
    }
  }, [standardSize, isSizeDisabled, isStandard]);
  useEffect(() => {
    if (!hasUserSigns && baseRow === BASEROW_USER_SIGNS) {
      setBaseRow(BASEROW_EMPTY);
    } else if (hasUserSigns && baseRow === BASEROW_EMPTY) {
      setBaseRow(BASEROW_USER_SIGNS);
    }
  }, [hasUserSigns, setBaseRow, baseRow]);
  useEffect(() => {
    if (largestUserRow > maxSignsPix) {
      setIsFillButtonDisabled(true);
      setSignsWarnings(true);
    } else {
      setSignsWarnings(false);
    }
  }, [largestUserRow, maxSignsPix]);
  const trackEventOnFillCoupon = () => {
    let options = {
      category: "Nya tipsen - Fyll i med PIX!",
      action: 'Fyll i kupong',
      opt_label: location.pathname
    };
    if (competition) {
      options = _objectSpread(_objectSpread({}, options), {}, {
        competition: competition.ownerType
      });
    }
    analytics.trackEvent(options);
  };
  const onCommitPix = analyticId => {
    const dispatchAction = () => {
      let options = {
        system: isRsystem ? rSystemSize : standardSize,
        productId,
        drawNumber,
        couponId,
        type: isRsystem ? XPERT_WAGER_RSYS : XPERT_WAGER_MSYS
      };
      if (analyticId) {
        options = _objectSpread(_objectSpread({}, options), {}, {
          baseRow: baseRows[analyticId],
          keepUserSigns: false,
          xpertAnalysisAuthorId: analyticId
        });
      } else if (baseRow === BASEROW_USER_SIGNS) {
        options = _objectSpread(_objectSpread({}, options), {}, {
          baseRow: userSigns,
          keepUserSigns: true
        });
      } else {
        options = _objectSpread(_objectSpread({}, options), {}, {
          baseRow: [],
          keepUserSigns: false
        });
      }
      if (competition) {
        var _competition$details;
        options = _objectSpread(_objectSpread({}, options), {}, {
          system: (_competition$details = competition.details) === null || _competition$details === void 0 ? void 0 : _competition$details.systemNumber
        });
      }
      trackEventOnFillCoupon();
      dispatch(generateSportPixCoupon({
        data: options,
        loadingMeta: {
          key: LoadingKeys.PIX,
          pause: 200
        }
      }));
    };
    if (hasUserSigns && (baseRow !== BASEROW_USER_SIGNS || analyticId > 0)) {
      svs.components.dialog.api.add(new svs.components.dialog.Confirm({
        actions: [{
          title: 'Avbryt'
        }, {
          callback() {
            svs.components.dialog.api.close();
            dispatchAction();
          },
          title: 'Fortsätt'
        }],
        message: [{
          text: 'Vill du fortsätta ändå?',
          type: svs.components.dialog.message.TEXT
        }],
        branding: svs.components.dialog.branding.SPORT,
        icon: 'dustbin',
        title: 'Dina tecken kommer att rensas'
      }));
    } else {
      dispatchAction();
    }
  };
  const XpertChoiseStandardComponent = isSelfService || isExtraSmall ? XpertBtnMsys : XpertSelectorSlider;
  const XpertChoiseRsystemComponent = XpertBtnRsys;
  const hasXpertSystems = isRsystem || isStandard && !hasOnlyOneSystem;
  const xpertElementsClassNames = ['xpert-elements'];
  const xpertBottomBtnsClassNames = ['tipsen-xperten-selector-bottom-btns'];
  if (isExtraSmall) {
    xpertElementsClassNames.push('xpert-elements-mobile');
  }
  if (!hasXpertSystems) {
    xpertElementsClassNames.push('xpert-elements-one-system');
    xpertBottomBtnsClassNames.push('tipsen-xperten-selector-bottom-btns-one-system');
  }
  return React.createElement("div", {
    className: xpertElementsClassNames.join(' ')
  }, React.createElement(ReactIconButton, {
    className: "xpert-close-btn",
    onClick: onCloseXpert
  }, React.createElement(ReactIcon, {
    icon: "close",
    size: "300"
  })), React.createElement(Choose, null, React.createElement(When, {
    condition: isRsystem
  }, React.createElement(XpertChoiseRsystemComponent, {
    isOpen: isOpen,
    isSystemDisabled: isSystemDisabled,
    setSize: setRsystemSize,
    size: rSystemSize,
    xpertSystems: rSystems
  })), React.createElement(When, {
    condition: isStandard && !hasOnlyOneSystem
  }, React.createElement(XpertChoiseStandardComponent, {
    isOpen: isOpen,
    isSizeDisabled: isSizeDisabled,
    setSize: setStandardSize,
    size: standardSize,
    xpertSystems: xpertMsys
  }))), React.createElement("div", {
    className: xpertBottomBtnsClassNames.join(' ')
  }, React.createElement(XpertButton, {
    isDisabled: isPixButtonDisabled,
    isLoading: isLoadingPix,
    onClick: () => {
      onCommitPix(0);
    }
  }, generateXpertCouponLabel), React.createElement(ForEach, {
    array: analyses,
    render: drawAnalysis => React.createElement(XpertButton, {
      isDisabled: isPixButtonDisabled,
      isLoading: isLoadingPix,
      key: drawAnalysis.id,
      onClick: () => {
        onCommitPix(drawAnalysis.authorProfile.id);
      }
    }, "Utg\xE5 fr\xE5n ".concat(drawAnalysis.authorProfile.authorShortName, "s analys"))
  })));
};
setGlobal('svs.components.tipsen.routePlaySpela.XpertSelector', XpertSelector);

 })(window);