(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/coupon-choice/coupon-toolbox/coupon-pix-choice.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/coupon-choice/coupon-toolbox/coupon-pix-choice.js');
"use strict";

const {
  XpertSelector
} = svs.components.tipsen.routePlaySpela;
const CouponPixChoice = _ref => {
  let {
    isOpen,
    onActivateNone
  } = _ref;
  return React.createElement("div", {
    "data-testid": "pix-choices"
  }, React.createElement(XpertSelector, {
    isOpen: isOpen,
    onCloseXpert: onActivateNone
  }));
};
setGlobal('svs.components.tipsen.routePlaySpela.couponChoice.CouponPixChoice', CouponPixChoice);

 })(window);