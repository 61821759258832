(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/system-selector/constants.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/system-selector/constants.js');
"use strict";

const {
  SYSTEM_RSYS,
  SYSTEM_USYS,
  SYSTEM_REDUCERA_FRITT
} = svs.components.tipsen.engine.constants.systems;
const systemConfig = {
  [SYSTEM_RSYS]: {
    label: 'R-System',
    icon: 'r-system'
  },
  [SYSTEM_USYS]: {
    label: 'U-System',
    icon: 'u-system'
  },
  [SYSTEM_REDUCERA_FRITT]: {
    label: 'Reducera Fritt',
    icon: 'slider'
  }
};
const NONE_SELECTED_SYSTEM = 'none';
setGlobal('svs.components.tipsen.routePlaySpela.systemSelector.constants', {
  systemConfig,
  NONE_SELECTED_SYSTEM
});

 })(window);