(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/settings-selector/settings-selector.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/settings-selector/settings-selector.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  ReactIconButton,
  ReactIcon
} = svs.ui;
const {
  analytics
} = svs.components;
const {
  TipsenModal,
  StickyBottom,
  StickyButtonClose,
  ModalHeader
} = svs.components.tipsen.tipsenModal;
const {
  selectShowCompressedCoupon,
  selectShowMinistatInfo,
  selectShowMatchAnalysesInCoupon
} = svs.components.tipsen.selectors;
const {
  selectDisplayName
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  ErrorBoundary
} = svs.components.errorBoundary;
const {
  useRef
} = React;
const {
  SettingsSelectorContainer
} = svs.components.tipsen.routePlaySpela;
const SettingsStickyBottom = _ref => {
  let {
    trackEventOnExitSettings,
    onClose
  } = _ref;
  return React.createElement(StickyBottom, null, React.createElement(StickyButtonClose, {
    onClick: e => {
      trackEventOnExitSettings();
      onClose(e);
    }
  }));
};
const getStartUpSettingValues = _ref2 => {
  let {
    showCompressedCoupon,
    showMinistat,
    showMatchAnalysesInCoupon,
    tipsinfoOptions
  } = _ref2;
  const tipsinfoValues = tipsinfoOptions === null || tipsinfoOptions === void 0 ? void 0 : tipsinfoOptions.map(tipsinfoItem => {
    const key = tipsinfoItem.key;
    const isSelected = tipsinfoItem.isSelected;
    return {
      key,
      isSelected
    };
  });
  const settingValues = {
    showCompressedCoupon,
    showMinistat,
    showMatchAnalysesInCoupon,
    tipsinfoValues
  };
  return settingValues;
};
const renderSettingsStickyBottom = (onClose, trackEventOnExitSettings) => React.createElement(SettingsStickyBottom, {
  onClose: onClose,
  trackEventOnExitSettings: trackEventOnExitSettings
});
const SettingsSelector = _ref3 => {
  let {
    tipsinfoOptions,
    isOpen,
    onClose
  } = _ref3;
  const displayName = useSelector(selectDisplayName);
  const isExtraSmall = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const showCompressedCoupon = useSelector(selectShowCompressedCoupon);
  const showMinistat = useSelector(selectShowMinistatInfo);
  const showMatchAnalysesInCoupon = useSelector(selectShowMatchAnalysesInCoupon);
  const startUpSettingValues = useRef(getStartUpSettingValues({
    showCompressedCoupon,
    showMinistat,
    showMatchAnalysesInCoupon,
    tipsinfoOptions
  }));
  const trackEventOnExitSettings = () => {
    const selectedOptions = tipsinfoOptions.filter(option => option.isSelected).map(option => option.title);
    analytics.trackEvent({
      category: "Nya tipsen - Inst\xE4llningar",
      action: 'Tipsinfo',
      opt_label: selectedOptions.join(', ')
    });
    if (startUpSettingValues.current.showCompressedCoupon !== showCompressedCoupon) {
      analytics.trackEvent({
        category: "Nya tipsen - Inst\xE4llningar",
        action: 'compact_coupon',
        opt_label: showCompressedCoupon
      });
    }
    if (startUpSettingValues.current.showMinistat !== showMinistat) {
      analytics.trackEvent({
        category: 'Nya Tipsen',
        action: 'Ministatistik aktiverad',
        opt_label: displayName
      });
    }
  };
  if (isExtraSmall) {
    return React.createElement(TipsenModal, {
      Bottom: () => renderSettingsStickyBottom(onClose, trackEventOnExitSettings),
      className: "tipsen-modal-window",
      isOpen: isOpen
    }, React.createElement("div", {
      className: "pg_coupon_settings_selector__container",
      "data-testid": "settings"
    }, React.createElement(ModalHeader, null, "Anpassa kupong"), React.createElement(SettingsSelectorContainer, null)));
  }
  return React.createElement(ErrorBoundary, null, React.createElement("div", {
    className: "pg_coupon_settings_selector__container",
    "data-testid": "settings"
  }, React.createElement(ReactIconButton, {
    className: "pg_coupon_settings_selector__close_btn",
    onClick: e => {
      trackEventOnExitSettings();
      onClose(e);
    }
  }, React.createElement(ReactIcon, {
    icon: "close",
    size: "300"
  })), React.createElement(SettingsSelectorContainer, null)));
};
setGlobal('svs.components.tipsen.routePlaySpela.SettingsSelector', SettingsSelector);

 })(window);