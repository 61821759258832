(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/reset-to-system.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/reset-to-system.js');
"use strict";

const {
  useEffect
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  engine
} = svs.components.tipsen;
const {
  selectSystem
} = engine.actions;
const {
  selectSystemType
} = engine.selectors.couponSelectors;
const {
  selectSystemTypes
} = engine.selectors.engineSelectors;
const {
  SYSTEM_MSYS
} = engine.constants.systems;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const ResetToSystem = () => {
  const couponId = useCouponId();
  const systemType = useSelector(state => selectSystemType(state, couponId));
  const systemTypes = useSelector(selectSystemTypes);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectSystem({
      systemTypes,
      couponId,
      system: systemTypes.indexOf(SYSTEM_MSYS)
    }));
  }, [dispatch, systemType, couponId, systemTypes]);
  return null;
};
setGlobal('svs.components.tipsen.routePlaySpela.ResetToSystem', ResetToSystem);

 })(window);