(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/xpert-selector/xpert-selector-small-screen.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/xpert-selector/xpert-selector-small-screen.js');
"use strict";

const {
  TipsenModal,
  StickyBottom,
  StickyButtonClose,
  StickyBottomButton
} = svs.components.tipsen.tipsenModal;
const {
  XpertBtnMsys,
  XpertBtnRsys
} = svs.components.tipsen.xpertenBtnSelector;
const {
  reactForm
} = svs.ui;
const {
  Fieldset,
  RadioGroup,
  RadioButton
} = reactForm;
const BASEROW_EMPTY = 'BASEROW_EMPTY';
const BASEROW_USER_SIGNS = 'BASEROW_USER_SIGNS';
const {
  ReactIcon
} = svs.ui;
const XpertStickyBottom = _ref => {
  let {
    onCloseXpert,
    isFillCouponBtnDisabled,
    onCommitPix,
    isSameSystem
  } = _ref;
  return React.createElement(StickyBottom, null, React.createElement(StickyButtonClose, {
    isInverted: false,
    isTransparent: true,
    onClick: onCloseXpert
  }), React.createElement(StickyBottomButton, {
    isBlock: true,
    isDisabled: isFillCouponBtnDisabled,
    isInverted: true,
    isThin: true,
    onClick: () => {
      onCommitPix();
      onCloseXpert();
    }
  }, isSameSystem ? 'Ny PIX!' : 'Fyll i kupong'));
};
const XpertSelectorSmallScreen = _ref2 => {
  let {
    analyses,
    baseRow,
    hasUserSigns,
    isFillCouponBtnDisabled,
    isOpen,
    isRsystem,
    isSameSystem,
    isSizeDisabled,
    onCloseXpert,
    onCommitPix,
    setBaseRow,
    setSize,
    size,
    xpertSystems
  } = _ref2;
  const XpertBtnComponent = isRsystem ? XpertBtnRsys : XpertBtnMsys;
  return React.createElement(TipsenModal, {
    Bottom: () => React.createElement(XpertStickyBottom, {
      isFillCouponBtnDisabled: isFillCouponBtnDisabled,
      isSameSystem: isSameSystem,
      onCloseXpert: onCloseXpert,
      onCommitPix: onCommitPix
    }),
    "data-testid": "pix-choices",
    isOpen: isOpen
  }, React.createElement(React.Fragment, null, React.createElement("div", {
    className: "margin-left-1-half"
  }, React.createElement("div", {
    className: "xpert-selector-header-container"
  }, React.createElement("div", {
    className: "xpert-selector-header"
  }, "Fyll i med", ' '), React.createElement(ReactIcon, {
    className: "tipsen-mobile-pix-icon",
    icon: "pix",
    size: "600"
  }))), React.createElement("p", {
    className: "margin-h-1 tipsen-mobile-pix-info-text"
  }, "PIX! erbjuder extra hj\xE4lp genom en kombination av experttips och slumpen."), React.createElement("div", {
    className: "f-550 tipsen-pix-selector-header-txt"
  }, "Utg\xE5 ifr\xE5n"), React.createElement(Fieldset, {
    className: "xpert-choices",
    legend: "V\xE4lj tecken att utg\xE5 ifr\xE5n",
    srOnly: true
  }, React.createElement(RadioGroup, {
    className: analyses.length > 0 ? 'three-col' : 'two-col',
    size: "200",
    thin: true
  }, React.createElement(RadioButton, {
    checked: baseRow === BASEROW_EMPTY,
    inline: true,
    name: "xpert-choice",
    onChange: e => {
      setBaseRow(e);
    },
    value: BASEROW_EMPTY
  }, "Grunden"), React.createElement(RadioButton, {
    checked: baseRow === BASEROW_USER_SIGNS,
    disabled: !hasUserSigns,
    inline: true,
    name: "xpert-choice",
    onChange: e => {
      setBaseRow(e);
    },
    value: BASEROW_USER_SIGNS
  }, "Egna val"), analyses.map(drawAnalysis => React.createElement(RadioButton, {
    checked: baseRow === drawAnalysis.authorProfile.id,
    inline: true,
    key: drawAnalysis.id,
    name: "xpert-choice",
    onChange: e => {
      setBaseRow(e);
    },
    value: drawAnalysis.authorProfile.id
  }, drawAnalysis.authorProfile.authorShortName)))), React.createElement(XpertBtnComponent, {
    isOpen: isOpen,
    isSystemDisabled: isSizeDisabled,
    setSize: setSize,
    size: size,
    xpertSystems: xpertSystems
  })));
};
setGlobal('svs.components.tipsen.routePlaySpela.XpertSelectorSmallScreen', XpertSelectorSmallScreen);

 })(window);