(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/coupon-choice/coupon-choice.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/coupon-choice/coupon-choice.js');
"use strict";

const {
  useState,
  useCallback
} = React;
const {
  COUPON_CHOICE_NONE,
  COUPON_CHOICE_PIX,
  COUPON_CHOICE_SETTINGS
} = svs.components.tipsen.routePlaySpela.couponChoice.constants.showCouponChoice;
const {
  CouponToolbox
} = svs.components.tipsen.routePlaySpela.couponChoice;
const {
  useIsCouponEmpty,
  useClearCoupon
} = svs.components.tipsen.hooks;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  useFilteredOutcomeInfoChoises,
  useFilteredTipsinfoOptions
} = svs.components.tipsen.tipsinfo;
const CouponChoice = () => {
  const couponId = useCouponId();
  const [activeChoice, setActiveChoice] = useState(COUPON_CHOICE_NONE);
  const onActivateChoice = useCallback(newActiveChoice => {
    setActiveChoice(oldActiveChoice => {
      if (oldActiveChoice === newActiveChoice) {
        newActiveChoice = COUPON_CHOICE_NONE;
      }
      return newActiveChoice;
    });
  }, [setActiveChoice]);
  const isShowingPix = activeChoice === COUPON_CHOICE_PIX;
  const isShowingSettings = activeChoice === COUPON_CHOICE_SETTINGS;
  const onActivatePix = useCallback(() => {
    onActivateChoice(COUPON_CHOICE_PIX);
  }, [onActivateChoice]);
  const onActivateSettings = useCallback(() => {
    onActivateChoice(COUPON_CHOICE_SETTINGS);
  }, [onActivateChoice]);
  const onActivateNone = useCallback(() => {
    onActivateChoice(COUPON_CHOICE_NONE);
  }, [onActivateChoice]);
  const onClearCoupon = useClearCoupon(couponId);
  const isCouponEmpty = useIsCouponEmpty(couponId);
  const tipsinfoOptions = useFilteredTipsinfoOptions();
  const outcomeInfoChoises = useFilteredOutcomeInfoChoises();
  return React.createElement("div", {
    className: "coupon-choices"
  }, React.createElement(CouponToolbox, {
    isCouponEmpty: isCouponEmpty,
    isShowingPix: isShowingPix,
    isShowingSettings: isShowingSettings,
    onActivateNone: onActivateNone,
    onActivatePix: onActivatePix,
    onActivateSettings: onActivateSettings,
    onClearCoupon: onClearCoupon,
    outcomeInfoChoises: outcomeInfoChoises,
    tipsinfoOptions: tipsinfoOptions
  }));
};
setGlobal('svs.components.tipsen.routePlaySpela.couponChoice.CouponChoice', CouponChoice);

 })(window);