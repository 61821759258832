(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/route-play-spela.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/route-play-spela.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;

const {
  useContext
} = React;
const {
  useHistory,
  matchPath
} = ReactRouterDOM;
const {
  useSelector
} = ReactRedux;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const hasPlayer = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
const {
  getProductCompetitionId
} = svs.components.lbUtils.brandMapping.fn;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  DisabledCoupon
} = svs.components.marketplaceCompetition.disabledCoupon;
const {
  selectActiveCompetitionId
} = svs.components.marketplaceCompetition.services.selectors.competitionSelectors;
const {
  useDrawSubscription
} = svs.components.sport.drawsRedux.hooks;
const {
  Play
} = svs.components.tipsen.routePlaySpela;
const {
  DetailViewRoutePlayHandler
} = svs.components.tipsen.detailViewRoutePlayHandler;
const {
  CouponAdditions
} = svs.components.tipsen.couponAdditions;
const {
  COUPON_STANDARD
} = svs.components.tipsen.engine.constants.couponTypes;
const {
  CouponIdProvider
} = svs.components.tipsen.couponId;
const {
  CenterToScreen
} = svs.components.tipsen.centerToScreen;
const {
  useCurrentCompetitions
} = svs.components.tipsen.hooks;
const {
  DrawContext
} = svs.components.tipsen.drawProvider;
const {
  getCompetitionFactoryInstance
} = svs.components.tipsen.competition.competitionFactoryInstance;
const {
  BetslipRegistry
} = svs.components.tipsen.betslipRegistry;
const DrawSubscriberLight = _ref => {
  let {
    productId,
    drawNumber
  } = _ref;
  useDrawSubscription(drawNumber, productId, {
    isLightUpdate: true
  });
  return null;
};
const RoutePlaySpela = _ref2 => {
  let {
    match
  } = _ref2;
  const {
    useCanPlayInCompetitionDraw
  } = getCompetitionFactoryInstance().getCompetitionHooksForCms();
  const competitions = useCurrentCompetitions();
  const history = useHistory();
  const {
    productId,
    drawNumber
  } = useContext(DrawContext);
  const compId = getProductCompetitionId(productId);
  const activeCompetitionId = useSelector(selectActiveCompetitionId);
  const canPlayInCompetition = useCanPlayInCompetitionDraw({
    productId,
    drawNumber
  }, compId);
  const shouldShowDisabledCoupon = activeCompetitionId ? !canPlayInCompetition && hasPlayer : false;
  const hasCompetition = Boolean(competitions.find(comp => comp.id === compId));
  if (!hasCompetition && (matchPath(location.pathname, {
    path: "".concat(svs.core.data.baseUrl, "/").concat(TipsenModuleGameUrl.TIPS_SM),
    exact: true,
    strict: false
  }) || matchPath(location.pathname, {
    path: "".concat(svs.core.data.baseUrl, "/").concat(TipsenModuleGameUrl.TIPS_EM),
    exact: true,
    strict: false
  }))) {
    history.push({
      pathname: '/',
      search: location.search
    });
  }
  return React.createElement(CouponIdProvider, {
    type: COUPON_STANDARD
  }, React.createElement(DrawSubscriberLight, {
    drawNumber: drawNumber,
    productId: productId
  }), React.createElement(CenterToScreen, null, !shouldShowDisabledCoupon && React.createElement(React.Fragment, null, React.createElement("div", {
    className: "grid-row tipsen-coupon-margin"
  }, React.createElement("div", {
    className: isSelfService ? 'col-md-6' : 'col-md-7'
  }, React.createElement(Play, {
    match: match
  })), React.createElement(DetailViewRoutePlayHandler, null)), React.createElement(CouponAdditions, null)), shouldShowDisabledCoupon && React.createElement(DisabledCoupon, {
    className: "tipsen-coupon-margin",
    drawNumber: drawNumber
  })), React.createElement(BetslipRegistry, null));
};
setGlobal('svs.components.tipsen.routePlaySpela.RoutePlaySpela', RoutePlaySpela);

 })(window);