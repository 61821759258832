(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/coupon-choice/show-coupon-choice.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/coupon-choice/show-coupon-choice.js');
"use strict";

const showCouponChoice = {
  COUPON_CHOICE_NONE: 'COUPON_CHOICE_NONE',
  COUPON_CHOICE_PIX: 'COUPON_CHOICE_PIX',
  COUPON_CHOICE_SETTINGS: 'COUPON_CHOICE_SETTINGS'
};
setGlobal('svs.components.tipsen.routePlaySpela.couponChoice.constants.showCouponChoice', showCouponChoice);

 })(window);