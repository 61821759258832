(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/coupon-choice/coupon-toolbox/coupon-tipsinfo-choice.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/coupon-choice/coupon-toolbox/coupon-tipsinfo-choice.js');
"use strict";

const {
  SettingsSelector
} = svs.components.tipsen.routePlaySpela;
const CouponTipsinfoChoice = _ref => {
  let {
    isOpen,
    onActivateNone,
    tipsinfoOptions
  } = _ref;
  return React.createElement(SettingsSelector, {
    isOpen: isOpen,
    onClose: onActivateNone,
    tipsinfoOptions: tipsinfoOptions
  });
};
setGlobal('svs.components.tipsen.routePlaySpela.couponChoice.CouponTipsinfoChoice', CouponTipsinfoChoice);

 })(window);