(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/play.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/play.js');
"use strict";

const {
  useContext
} = React;
const {
  Route,
  Switch
} = ReactRouterDOM;
const {
  ResetToSystem
} = svs.components.tipsen.routePlaySpela;
const {
  SystemSelector
} = svs.components.tipsen.routePlaySpela.systemSelector;
const {
  CouponChoice
} = svs.components.tipsen.routePlaySpela.couponChoice;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  Coupon
} = svs.components.tipsen.coupon;
const {
  useCouponId,
  PaymentContext
} = svs.components.tipsen.couponId;
const {
  useActionListener
} = svs.components.tipsen.hooks;
const {
  useComponentFromRegistry
} = svs.components.tipsen.componentRegistry;
const {
  RenderProvider
} = svs.component.tipsen.renderContext;
const {
  pageTypes
} = svs.component.tipsen.renderContext.constants;
const Play = _ref => {
  let {
    match
  } = _ref;
  const couponId = useCouponId();
  const placeBetAsync = useContext(PaymentContext);
  useActionListener({
    couponId,
    placeBetAsync
  });
  const CouponComponent = useComponentFromRegistry('coupon', React.createElement(Coupon, null));
  return React.createElement(React.Fragment, null, React.createElement(Switch, null, React.createElement(Route, {
    component: SystemSelector,
    path: "".concat(match.path).concat(TipsenModuleGameUrl.SYSTEM)
  }), React.createElement(Route, {
    component: ResetToSystem
  })), React.createElement(RenderProvider, {
    pageType: pageTypes.DEFAULT
  }, React.createElement(CouponChoice, null), React.createElement(CouponComponent, null)));
};
setGlobal('svs.components.tipsen.routePlaySpela.Play', Play);

 })(window);