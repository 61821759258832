(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/system-selector/system-selector-component.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/system-selector/system-selector-component.js');
"use strict";

const MIN_SYSTEMS = 5;
const DISPLAY_COUNT = 5;
const COLUMN_SIZE = 1;
const SYSTEM_CHOOSE = 'Visa systemvarianter';
const {
  ReactButton
} = svs.ui;
const {
  useState,
  useMemo
} = React;
const {
  getSystemLabelFromNumber
} = svs.components.sport.systems;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  Form,
  ControlGroup,
  Select,
  Option
} = svs.ui.reactForm;
const systemBtnBaseClasses = 'margin-half system-selector-button f-bold';
const SystemSelectorComponent = _ref => {
  let {
    systems,
    system: selectedSystem,
    systemType,
    onClick
  } = _ref;
  const isExtraSmall = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const [showAllSystems, setShowAllSystems] = useState(false);
  const onToggleShowAllSystems = () => {
    setShowAllSystems(previousState => !previousState);
  };
  const systemsToShow = useMemo(() => {
    if (showAllSystems) {
      return systems;
    }
    const collapsedIndex = systems.indexOf(selectedSystem);
    const displayShift = Math.ceil(DISPLAY_COUNT / 2) + Math.floor(COLUMN_SIZE / 2) - collapsedIndex % COLUMN_SIZE; 
    let minIndex = Math.max(collapsedIndex + displayShift - DISPLAY_COUNT, 0);
    const maxIndex = Math.min(minIndex + DISPLAY_COUNT, systems.length);
    if (maxIndex - minIndex < DISPLAY_COUNT) {
      minIndex = Math.max(maxIndex - DISPLAY_COUNT, 0);
    }
    return systems.slice(minIndex, maxIndex);
  }, [systems, showAllSystems, selectedSystem]);
  if (!isExtraSmall) {
    return React.createElement("div", {
      className: "tipsen-selector-systems",
      role: "radiogroup"
    }, systemsToShow.map(systemNum => React.createElement(ReactButton, {
      "aria-checked": systemNum === selectedSystem,
      className: systemBtnBaseClasses,
      key: systemNum,
      onClick: () => {
        onClick(systemType, systemNum);
      },
      role: "radio",
      thin: true,
      transparent: true
    }, React.createElement("span", {
      className: "f-200"
    }, getSystemLabelFromNumber(systemNum)))), systems.length > MIN_SYSTEMS && React.createElement(ReactButton, {
      "aria-expanded": showAllSystems,
      className: systemBtnBaseClasses,
      onClick: onToggleShowAllSystems,
      thin: true,
      transparent: true
    }, React.createElement("span", {
      className: "f-300"
    }, showAllSystems ? 'Visa färre' : 'Visa alla')));
  }
  return React.createElement(Form, {
    isVertical: true
  }, React.createElement(ControlGroup, {
    v2: true
  }, React.createElement(Select, {
    "data-testid": "system-selector",
    onChange: event => onClick(systemType, Number(event.target.value)),
    v2: true,
    value: selectedSystem || SYSTEM_CHOOSE
  }, React.createElement(Option, {
    value: ""
  }, SYSTEM_CHOOSE), systems.map(systemNum => React.createElement(Option, {
    key: systemNum,
    value: systemNum
  }, getSystemLabelFromNumber(systemNum))))));
};
setGlobal('svs.components.tipsen.routePlaySpela.systemSelector.SystemSelectorComponent', SystemSelectorComponent);

 })(window);